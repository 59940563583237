import utility from '@/common/utility'

const G24SpreadInfo = {
  sheetName: 'G24',
  columns: [
    {
      label: '序号',
      dataKey: 'serialNo',
      editable: false,
      width: '50'
    },
    {
      label: '工程名称',
      dataKey: 'engineeringListName',
      width: '100',
      children: [
        {
          label: '工程类别',
          dataKey: 'parentEngineeringListName',
          width: '150'
        },
        {
          label: '工程名称',
          dataKey: 'engineeringListName',
          width: '200'
        }
      ]
    },
    {
      label: '单位',
      dataKey: 'unit',
      width: '90'
    },
    {
      label: '合同数量',
      dataKey: 'num',
      children: [
        {
          label: '原合同数量',
          dataKey: 'num',
          width: '140'
        },
        {
          label: '按施工图调整后数量',
          dataKey: 'pictureNum',
          width: '140'
        },
        {
          label: '变更数量',
          dataKey: 'changeNum',
          width: '140'
        },
        {
          label: '变更后数量',
          dataKey: 'afterChangeNum',
          width: '140'
        }
      ]
    },
    {
      label: '本年度计划工程量',
      dataKey: 'currentYearNum',
      editable: true,
      width: '90'
    },
    {
      label: '实体工程形象进度计划',
      dataKey: 'months',
      children: [
        {
          label: '1月',
          dataKey: 'month1',
          editable: true,
          validator (row) {
            if (row.month1 !== undefined && row.month1 !== null && row.month1 !== '') {
              const len = row.unit.split('\\').length
              const month1List = row.month1.toString().split('\\')
              if (len !== month1List.length) {
                return false
              }
              if (!month1List.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '60'
        },
        {
          label: '2月',
          dataKey: 'month2',
          editable: true,
          validator (row) {
            if (row.month2 !== undefined && row.month2 !== null && row.month2 !== '') {
              const len = row.unit.split('\\').length
              const month2List = row.month2.toString().split('\\')
              if (len !== month2List.length) {
                return false
              }
              if (!month2List.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '60'
        },
        {
          label: '3月',
          dataKey: 'month3',
          editable: true,
          validator (row) {
            if (row.month3 !== undefined && row.month3 !== null && row.month3 !== '') {
              const len = row.unit.split('\\').length
              const month3List = row.month3.toString().split('\\')
              if (len !== month3List.length) {
                return false
              }
              if (!month3List.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '60'
        },
        {
          label: '4月',
          dataKey: 'month4',
          editable: true,
          validator (row) {
            if (row.month4 !== undefined && row.month4 !== null && row.month4 !== '') {
              const len = row.unit.split('\\').length
              const month4List = row.month4.toString().split('\\')
              if (len !== month4List.length) {
                return false
              }
              if (!month4List.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '60'
        },
        {
          label: '5月',
          dataKey: 'month5',
          editable: true,
          validator (row) {
            if (row.month5 !== undefined && row.month5 !== null && row.month5 !== '') {
              const len = row.unit.split('\\').length
              const month5List = row.month5.toString().split('\\')
              if (len !== month5List.length) {
                return false
              }
              if (!month5List.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '60'
        },
        {
          label: '6月',
          dataKey: 'month6',
          editable: true,
          validator (row) {
            if (row.month6 !== undefined && row.month6 !== null && row.month6 !== '') {
              const len = row.unit.split('\\').length
              const month6List = row.month6.toString().split('\\')
              if (len !== month6List.length) {
                return false
              }
              if (!month6List.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '60'
        },
        {
          label: '7月',
          dataKey: 'month7',
          editable: true,
          validator (row) {
            if (row.month7 !== undefined && row.month7 !== null && row.month7 !== '') {
              const len = row.unit.split('\\').length
              const month7List = row.month7.toString().split('\\')
              if (len !== month7List.length) {
                return false
              }
              if (!month7List.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '60'
        },
        {
          label: '8月',
          dataKey: 'month8',
          editable: true,
          validator (row) {
            if (row.month8 !== undefined && row.month8 !== null && row.month8 !== '') {
              const len = row.unit.split('\\').length
              const month8List = row.month8.toString().split('\\')
              if (len !== month8List.length) {
                return false
              }
              if (!month8List.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '60'
        },
        {
          label: '9月',
          dataKey: 'month9',
          editable: true,
          validator (row) {
            if (row.month9 !== undefined && row.month9 !== null && row.month9 !== '') {
              const len = row.unit.split('\\').length
              const month9List = row.month9.toString().split('\\')
              if (len !== month9List.length) {
                return false
              }
              if (!month9List.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '60'
        },
        {
          label: '10月',
          dataKey: 'month10',
          editable: true,
          validator (row) {
            if (row.month10 !== undefined && row.month10 !== null && row.month10 !== '') {
              const len = row.unit.split('\\').length
              const month10List = row.month10.toString().split('\\')
              if (len !== month10List.length) {
                return false
              }
              if (!month10List.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '60'
        },
        {
          label: '11月',
          dataKey: 'month11',
          editable: true,
          validator (row) {
            if (row.month11 !== undefined && row.month11 !== null && row.month11 !== '') {
              const len = row.unit.split('\\').length
              const month11List = row.month11.toString().split('\\')
              if (len !== month11List.length) {
                return false
              }
              if (!month11List.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          width: '60'
        },
        {
          label: '12月',
          dataKey: 'month12',
          validator (row) {
            if (row.month12 !== undefined && row.month12 !== null && row.month12 !== '') {
              const len = row.unit.split('\\').length
              const month12List = row.month12.toString().split('\\')
              if (len !== month12List.length) {
                return false
              }
              if (!month12List.every(item => !isNaN(item))) {
                return false
              }
            }
            return true
          },
          formula (row) {
            let sum = row.currentYearNum
            for (let i = 1; i < 12; ++i) {
              let monthNumList = row[`month${i}`].toString().split('\\')
              sum = sum.toString().split('\\').map((sumItem, index) => {
                return utility.floatSubstract(sumItem, monthNumList[index])
              }).join('\\')
            }
            return sum
          },
          width: '60'
        }
      ]
    }
  ]
}

export default G24SpreadInfo

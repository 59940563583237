<template>
  <div class="g24">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handleSaveClick">保存</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div class="spread-wrapper">
      <my-spread
        v-if="defaultDataList.length"
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        >
      </my-spread>
    </div>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import auth from '@/common/auth'
import G24SpreadInfo from '@/views/PlanStatManage/HeaderColumns/G24SpreadInfo'
import g24Service from '@/services/g24Service'
import MySpread from '@/components/Spread/MySpreadOld'
import G24Model from '@/model/G24Model'
import { mapGetters } from 'vuex'

export default {
  name: 'g24',
  components: {
    MySpread,
    MySearchBar
  },
  data () {
    return {
      defaultDataList: [],
      spreadInfo: G24SpreadInfo
    }
  },
  computed: {
    tenderGuid () {
      return auth.getUserInfo().tenderGuid
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      year: 'getYear',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  methods: {
    handleSaveClick () {
      // 检查是否有错误
      // this.$refs.mySpread.validate()
      this.$myLoading()

      let validateData = this.$refs.mySpread.getData().filter(item => {
        let hasData = false
        if (item.currentYearNum && item.currentYearNum.toString() !== '') {
          hasData = true
        }
        for (let i = 1; i < 13; ++i) {
          if (item[`month${i}`].toString() !== '') {
            hasData = true
          }
        }
        return hasData
      })
      for (let i = 0; i < validateData.length; ++i) {
        const item = validateData[i]
        console.log('month data', item)
        if (item.unit.split('\\').length !== item.currentYearNum.toString().split('\\').length) {
          this.$loading().close()
          this.$alert('请检查您的输入！')
          return
        }
        for (let k = 1; k < 13; ++k) {
          let monthNumList = item[`month${k}`].toString().split('\\')
          if (item.unit.split('\\').length !== monthNumList.length) {
            this.$loading().close()
            this.$alert('请检查您的输入！')
            return
          }
          for (let m = 0; m < monthNumList.length; ++m) {
            let num = monthNumList[m]
            if (isNaN(num)) {
              this.$loading().close()
              this.$alert('请检查您的输入！')
              return
            }
            num = parseFloat(num)
            console.log(num)
            if (num < 0) {
              this.$loading().close()
              this.$alert('请检查您的输入！')
              return
            }
          }
        }
      }

      let data = this.$refs.mySpread.getData().map((item, index) => {
        item.planStatExaminationGuid = this.planStatExaminationGuid
        item.engineeringListGuid = this.defaultDataList[index].engineeringListGuid
        item.tenderGuid = this.tenderGuid
        item.year = this.year
        return item
      }).filter(item => {
        for (let i = 1; i < 13; ++i) {
          if (item[`month${i}`] === '') {
            return false
          }
        }
        return true
      }).map(item => {
        for (let i = 1; i < 13; ++i) {
          item[`month${i}`] = item[`month${i}`].toString()
        }
        item.currentYearNum = item.currentYearNum.toString()
        return new G24Model(item)
      })

      g24Service.batchAdd(data)
        .then(res => {
          this.$loading().close()
          this.$message({
            type: 'success',
            message: '保存成功！'
          })
          this.$router.push('/g24Index')
        })
        .catch(err => {
          console.log(err)
          this.$loading().close()
          this.$message({
            type: 'error',
            message: '保存失败，请重试！'
          })
        })
    },
    getDefaultData () {
      g24Service.getDefaultData(this.tenderGuid, this.planStatExaminationGuid, this.year)
        .then(res => {
          if (res.data.code === 1) {
            this.defaultDataList = res.data.data.map((item, i) => {
              item.serialNo = i + 1
              return item
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败，请重试！'
          })
        })
    }
  },
  created () {
    this.getDefaultData()
  }
}
</script>

<style scoped lang="scss">
.g24 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
